.rotated {
  transform: rotate(-25deg);
  margin-top: 40px;

  border-radius: 35px;
}

@media (max-width: 750px) {
  .block {
      text-align: center;
  }

  .rotated {
      transform: rotate(0deg);
      margin: auto;
      padding: auto;

  }

}